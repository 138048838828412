import api from "!../../.yarn/__virtual__/style-loader-virtual-0e083c4e9f/0/cache/style-loader-npm-2.0.0-b9a5c4a2aa-21425246a5.zip/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../.yarn/__virtual__/css-loader-virtual-9392e7c121/0/cache/css-loader-npm-5.2.0-c409834e49-1d3a8b6362.zip/node_modules/css-loader/dist/cjs.js!../../.yarn/__virtual__/postcss-loader-virtual-721d64e4c4/0/cache/postcss-loader-npm-6.2.1-45828eb0de-e40ae79c3e.zip/node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-5.use[2]!../../.yarn/__virtual__/sass-loader-virtual-ce737badc2/0/cache/sass-loader-npm-11.0.1-5dad8a423c-23b4b46b0d.zip/node_modules/sass-loader/dist/cjs.js??clonedRuleSet-5.use[3]!./style.scss";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};